import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NotificationOverviewComponent } from './notification-overview/notification-overview.component';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationsComponent } from './notifications.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [NotificationsComponent, NotificationOverviewComponent],
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    CardModule,
    ProgressSpinnerModule,
    DialogModule,
    ReactiveFormsModule,
    FormsModule,
    RadioButtonModule,
    CheckboxModule,
    ToastModule,
    ChipModule,
    AvatarModule,
    BadgeModule,
    SkeletonModule,
    TieredMenuModule,
    InfiniteScrollModule,
    SharedModule,
    SelectButtonModule,
    DividerModule,
  ],
})
export class NotificationsModule {}
