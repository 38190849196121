import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getNotificationSummary() {
    return this.http.get(this.apiURL + '/api/notifications/summary');
  }

  getNotificationsList(page: number) {
    return this.http.get(
      this.apiURL +
        '/api/notifications?page=' +
        page +
        '&sort=triggerTime,desc',
    );
  }

  getNotificationsReadList(page: number) {
    return this.http.get(
      this.apiURL +
        '/api/notifications/read?page=' +
        page +
        '&sort=triggerTime,desc',
    );
  }

  markAsRead(id: string) {
    return this.http.put(
      `${this.apiURL}/api/notifications/${id}/read/true`,
      {},
    );
  }

  markAsUnread(id: string) {
    return this.http.put(
      `${this.apiURL}/api/notifications/${id}/read/false`,
      {},
    );
  }

  remove(id: string) {
    return this.http.put(`${this.apiURL}/api/notifications/${id}/remove`, {});
  }

  getAllUnreadNotifications(page: number) {
    return this.http.get(
      this.apiURL +
        '/api/notifications/unread?page=' +
        page +
        '&sort=triggerTime,desc',
    );
  }

  markAllAsRead() {
    return this.http.put(`${this.apiURL}/api/notifications/read`, {});
  }
}
